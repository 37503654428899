import https from "./https"
import store from "@/store"

const uploadFileApi = {
  getUploadFiles (organization, params) {
    return https.get(`admin/organizations/${organization.id}/upload-files`, { params })
  },
  getUploadFile (organization, uploadFileId) {
    return https.get(`admin/organizations/${organization.id}/upload-files/${uploadFileId}`)
  },
  updateUploadFile (organization, params) {
    return https.put(`admin/organizations/${organization.id}/upload-files/${params.id}`, params)
  },
  removeUploadFile (organization, params) {
    const url = 'admin/organizations/' + organization.id + '/upload-files/' + params.id;
    return https.delete(url)
  },
  storeUploadFile (params) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id  + '/upload-files'

    return https.post(url, params)
  },
  getCategoryOptions() {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id  + '/upload-files/category-options'

    return https.get(url)
  }
}

export default uploadFileApi
